<template>
  <div class="Client">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0">
        <banner
          title="ticket"
          :breadcrumb="[
            { label: 'superadmin' },
            { label: 'ticket' },
          ]"
        >
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="content-body">
          <!-- users list start -->
          <section>
            <div class="row" style="margin-top: -50px">
              <div class="col-xl-6 col-12" style="padding: 5px 5px 5px 5px">
                <div
                  class="card custom-card"
                
                >
                  <div class="card-body">
                    <h4 style="color: #00364f"><strong>Ticket</strong></h4>
                    <div
                      class="row p-1"
                      style="
                        color: #00364f;
                        background-color: #e3e3e3;
                        border-radius: 10px;
                      "
                    >
                      <div class="col-12" style="padding:5px 5px 5px 5px">
                        <strong>Ticket ID: </strong><span>{{ ticketData.ticketNo }}</span>
                      </div>
                     
                      <div class="col-12" style="padding:5px 5px 5px 5px">
                        <strong>Department : </strong
                        ><span v-if="ticketData.department">{{ ticketData.department.name }}</span>
                      </div>

                 
                      <div class="col-12" style="padding:5px 5px 5px 5px">
                        <strong>Create: </strong
                        ><span>{{ dateTimeWithTime(ticketData.created_at) }}</span>
                      </div>
                      <div class="col-12" style="padding:5px 5px 5px 5px">
                        <strong>Last Update: </strong
                        ><span>{{ dateTimeWithTime(ticketData.updated_at) }}</span>
                      </div>
                      <div class="col-12" style="padding:5px 5px 5px 5px">
                        <strong>Subject: </strong><span>{{ ticketData.subject }}</span>
                      </div>

                      <div class="col-12" style="padding:5px 5px 5px 5px">
                        <strong>Message: </strong><br />&nbsp; &nbsp; &nbsp;<span>{{
                          ticketData.message
                        }}</span>
                      </div>
                      <div class="col-12"  style="padding:5px 5px 5px 5px">
                        <strong>Status: &nbsp; &nbsp;</strong
                        ><span
                          class="btn btn-sm"
                          v-if="ticketData.status == 'open'"
                          style="
                            width: 60px;
                            background-color: green;
                            color: white;
                            box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                            border-radius: 50px;
                            font-size: 10px;
                            padding: 3px 3px 3px 3px;
                          "
                          >{{ ticketData.status }}
                        </span>
                        <span
                          class="btn btn-sm"
                          v-else
                          style="
                            width: 60px;
                            background-color: #f21000;
                            color: white;
                            box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                            border-radius: 50px;
                            font-size: 10px;
                            padding: 3px 3px 3px 3px;
                          "
                          >{{ ticketData.status }}
                        </span>
                      </div>
                      <div class="col-xl-6 col-6" style="padding: 5px 5px 5px 5px">
                        
                        <strong >Attachments : </strong>
                
                      </div>
                      <div
                        class="col-xl-6 col-6 text-end"
                        style="padding: 5px 5px 5px 5px"
                      >
                      
              <button
              @click="loadTicketDoccuments()"
              data-bs-toggle="modal"
                                    data-bs-target="#atachmentModal"
                style="padding: 5px 4.5px 5px 4.5px; background-color: #f21000"
                class="btn btns text-white btn-sm"
              >
              <i class="fa fa-paperclip" aria-hidden="true"></i>
              </button>

                      </div>
                    </div>

                    <form
                      @submit.prevent="createReplyticket()"
                      v-if="ticketData.status == 'open'"
                    >
                      <div class="row mt-2">
                        <div class="col-12">
                          <label
                            ><strong style="color: #00364f">Reply here <samll style="color:#f21000">*</samll></strong></label
                          >
                          <textarea
                          required
                            v-model="form.message"
                            class="form-control"
                            placeholder="Message"
                            rows="4"
                          ></textarea>

                          <small style="color: red" v-if="form.errors"
                  ><span v-if="form.errors.error"
                    ><span v-if="form.errors.error.message">{{
                      form.errors.error.message[0]
                    }}</span></span
                  ></small
                >


                        </div>
                        <div class="col-12 pt-1">
                          <button
                            type="submit"
                            class="btn btn-sm text-white"
                            style="background-color: #00364f"
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6 col-12"
                style="padding: 5px 5px 5px 5px"
                v-if="details.length != 0"
              >
                <div
                  class="card custom-card"
                
                >
                  <div class="card-body" style="padding: 8px 8px 8px 8px">
                    <h3><strong style="color: #00364f">&nbsp;Ticket Replies</strong></h3>
                    <div
                      class="row border border-1"
                      style="
                        margin: 5px 5px 10px 5px;
                        padding: 5px 5px 5px 5px;
                        border-radius: 5px;
                        box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
                      "
                      v-for="(detail, index) in details"
                      :key="index"
                    >
                      <div class="col-12">
                        <div class="row">
                          <div class="col-6">
                            <h5 style="color: #00364f">
                              <strong v-if="detail.department">{{
                                detail.department.name
                              }}</strong>
                            </h5>
                            <p v-if="detail" style="color: #f21000; margin-top: -8px">
                              {{ detail.relation_type }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <hr style="margin-top: -8px" />
                      <div
                        class="col-12"
                        style="
                          color: black;

                          border-radius: 3px;
                          background-color: #e3e3e3;
                          padding: 10px 10px 10px 10px;
                        "
                      >
                        {{ detail.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- users list ends -->
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>




<!-- ticket Documents Model Here  -->


    <!-- Modal -->
    <div
    class="modal fade"
    id="atachmentModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">Ticket</strong>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12" v-if="msgImgError1">
              <div class="alert alert-danger p-1" role="alert">
                <small>{{ msgImgError1 }}</small>
              </div>
            </div>
          </div>


            <table class="table table-hover table-sm" style="font-size: 14px">
              <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                <th>+</th>
                <th class="text-end">Download</th>
              </tr>
              <tbody>
                <tr v-for="(atc,index) in ticketDoccuments" :key="index">
                  <td>
                
                    <img style="width:30px" :src="ticketFinder(atc)" alt="">
                  </td>

                  <td class="text-truncate text-end">
           
  <!-- <label :for="`formFile${atc.id}`" class="form-label"> -->
    <button

    @click="doccFinder(atc)"
                      style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                      class="btn btns text-white btn-sm"
                    >
                      <i class="fa fa-download" aria-hidden="true"></i>
                    </button>

      <!-- <img
                        style="width: 25px; height: 25px"
                        src="/assets/image/download.png"
                      /> -->
                      <!-- </label> -->
  <!-- <input  @change="uploadAttachment($event,atc) " class="form-control" type="file" :id="`formFile${atc.id}`" style="position:absolute;top:-150000000000000px"> -->

                  </td>
                </tr>


              
              </tbody>
            </table>


            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              
            </div>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Banner from "../../../components/super-admin/comman/Banner.vue";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
// import Form from "vform";
// import jQuery from "jquery";
// let $ = jQuery;
export default {
  name: "SuperAdminViewTicketView1",
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      ticketDoccuments:[],
      attachment:this.$store.state.placeholderImg,
      attachmentPdf:'',
      extensionAttachment:'',

      atchUrl: "",
      departMents: [],
      ticketData: {},
      loading: false,
      clients: [],
      details: [],
      ticketDocument: [],
      catchStatus: "",
      client_service_id: null,
      stsCheck: "",
      edit: "false",
      name: "",
      form: {
        message: "",
        remark: "",
        ticket_id: null,
        errors:{}
      },
      msgImgError1: "",

      //   form: new Form({
      //     client_service_id: null,
      //     remark: "",
      //     errors: {},
      //   }),
    };
  },
  methods: {
    loadTicketDoccuments() {
      this.loading = true;
      this.$axios
        .get(`superadmin/clientserviceticket/document?ticket_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          this.ticketDoccuments = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
    openWindow(url) {
      window.open(`${url}`);
    },
// new 
    ticketFinder(ticket){
      var attachment

      if (this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/thumbs/" +
          ticket.attachment) {
        attachment =
          this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/thumbs/" +
          ticket.attachment;
        // this.atchUrl = attachment;
      
      } 
      
      if (this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/" +
          ticket.attachment) {
        attachment =
          this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/" +
          ticket.attachment;
        // this.atchUrl = attachment;

      } 

var arr = {}

      console.log(attachment)
var pdf = attachment
 var  extensionAttachment = attachment.split(".").pop();
    
arr.attachment = attachment
arr.pdf = pdf
arr.extensionAttachment = extensionAttachment

  if (extensionAttachment == "pdf" || extensionAttachment == "PDF") {
    arr.attachment =  "/assets/image/pdf.png";
            }

return arr.attachment;



      // this.attachmentPdf = this.atchUrl;
      //   this.extensionAttachment = this.coiPreImg.split(".").pop();
    },
// old
    doccFinder(ticket) {
//       console.log('===================');
// var ticket = this.ticketDocument
//   console.log(data);
//   console.log('===================');
      var attachment

// var atchUrl

      if (this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/thumbs/" +
          ticket.attachment) {
        attachment =
          this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/thumbs/" +
          ticket.attachment;
        // atchUrl = attachment;
      
      } 
      
      if (this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/" +
          ticket.attachment) {
        attachment =
          this.$store.state.imgUrl +
          "/tickets/" +
          ticket.ticket_id +
          "/" +
          ticket.attachment;
        // atchUrl = attachment;

      } 


      console.log(attachment)

      // this.attachmentPdf = atchUrl;
      //   this.extensionAttachment = atchUrl.split(".").pop();
     this.openWindow(attachment)
       




    
    },
   
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },

    deletePackage(id) {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be Delete it",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00364f",
          cancelButtonColor: "#f21000",
          confirmButtonText: "Yes, delete it!",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$axios
              .delete(`superadmin/clientserviceticket/${id}`, {
                headers: { Authorization: "Bearer " + localStorage.accessToken },
              })
              .then((res) => {
                res;
                toast.success("Ticket Deleted  Succesfully", {
                  autoClose: 1000,
                });
                this.loadTicket();
              })
              .catch((error) => {
                error;
              });
          }
        });
    },
    attachmentImageUpload(e) {
      this.msgImgError1 = "";
      if (e.target.files["0"].size > 2 * (1024 * 1024)) {
        e.preventDefault();
        this.msgImgError1 = "Attachment Size Too Big 2MB";
        return false;
      } else {
        // var fileName;
        // fileName = document.querySelector("#file-input1").value;
        // this.extensionProfile = fileName.split(".").pop();
        // if (
        //   this.extensionProfile == "png" ||
        //   this.extensionProfile == "jpg" ||
        //   this.extensionProfile == "jpeg"
        // ) {
        //   this.profilePreImg = URL.createObjectURL(e.target.files["0"]);
        this.form.attachment = e.target.files["0"];
        // } else {
        //   this.msgImgError1 = "File Type Must JPG , PNG, JPEG";
        // }
      }
    },

    createReplyticket() {
      this.form.ticket_id = this.$route.params.id;
      this.loading = true;
      this.$axios
        .post("superadmin/clientserviceticket/detail", this.form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res.data.data.data);
          // this.clients = res.data.data.data;
          toast.success("Form Was Sent  Succesfully ", {
            autoClose: 1000,
          });
          this.form = {};
          //   $("#exampleModal").modal("hide");
          this.loadClientservices();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          this.form.errors = error.response.data;
        })
    },

    loadClientservices() {
      this.loading = true;
      this.$axios
        .get(`superadmin/clientserviceticket/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          if (res.data.data) {
            this.ticketData = res.data.data;
            this.ticketDocument = res.data.data.ticket_document;
            this.details = res.data.data.ticket_detail;
            // this.doccFinder(this.ticketData)
          }
        })
        .finally(() => (this.loading = false));
    },
    loadDepartments() {
      this.loading = true;
      this.$axios
        .get(`superadmin/department?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.departMents = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.superAdminPageTitles.ticketView
    console.log(this.$store.state.superAdminPageTitles.ticketView)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClientservices();
      this.loadDepartments();
      this.name = JSON.parse(localStorage.getItem("userData")).name;

            
      this.loadPageTitle()
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}
</style>
